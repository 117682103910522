import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Play } from "@phosphor-icons/react";
import { blueGrey } from '@mui/material/colors';
import VideoJS from '../VideoJSComponent/VideoJSComponent'
import TagListComponenet from './TagListComponenet.js'
import Tags from '../../model/Tags.js'
import { MediaFile } from "../../model/MediaFile"
import { TimeUtil } from "../../model/TimeUtil";
import { useOutletContext } from "react-router-dom";

export default function TagIndex({}) {

  const [localStorage] = useOutletContext()

  const [tagIndex, setTagIndex] = React.useState({})
  const [selectedTag, setSelectedTag] = React.useState({})

  const [startTime, setStartTime] = React.useState(0)
  const [selectedMedia, setSelectedMedia] = React.useState(null)
  const [vjsTrigger, setVjsTrigger] = React.useState(0)   // A hack to trigger an effect (play at timestamp) on the videojs componenet without
                                                          // any other state change

  React.useEffect( () => {
    const loadTags = async () => {
      const tagIndex = await Tags.createTagIndex(localStorage)
      setTagIndex(tagIndex)
    }
    loadTags()
  }, [])

  const onTagClick = (e) => {
    let tag = e.target.closest("[data-tagname]").getAttribute('data-tagname')
    setSelectedTag(tagIndex[tag])
  }

  const onCommentPlayClick = async (e) => {
    let mediasrc = e.target.closest("[data-mediasrc]").getAttribute('data-mediasrc')
    let startTime = e.target.closest("[data-starttime]").getAttribute('data-starttime')

    const selectedMedia = await MediaFile.fromFilePath(localStorage, mediasrc)
    await selectedMedia.loadFiles(localStorage)

    setSelectedMedia(selectedMedia)
    setStartTime(startTime)
    setVjsTrigger(vjsTrigger + 1)
  }

  return(
    <Box style={{ display: 'flex', height: '100%', maxWidth: '100%' }}>

      <TagListComponenet
        tagIndex={tagIndex}
        onTagClick={onTagClick}
      />

      <Grid item container sx={{ flexGrow: 1, my: 2 }}>
        <Grid item xs={7} sx={{px: 4}} container direction="column" style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
          <div>
            {Object.values(selectedTag).map( (annotation, index) => (
                <Box
                key={index}
                sx={{
                  p: 1,
                  mb: 2,
                  border: 1,
                  borderColor: 'grey.200',
                  borderRadius: 1,
                  backgroundColor: 'grey.50',
                }}
                >
                  <Button
                    data-mediasrc={annotation.mediaFile.filename}
                    data-starttime={annotation.start}
                    variant="outlined"
                    onClick={ (e) => onCommentPlayClick(e) }
                    startIcon={<Play size={16} weight="fill" />}
                    size="small">
                    {annotation.mediaFile.basename()} &nbsp; <span style={{color: blueGrey[300]}}>@{TimeUtil.secondsToDisplayFormat(annotation.start)}</span> 
                  </Button>
                  <Typography mt={1}>
                    {annotation.comment}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{color: blueGrey[300]}}>
                    {annotation.tags.join(', ')}
                  </Typography>
                </Box>
            ))}
          </div>
        </Grid>

        <Grid item xs={5} sx={{pr: 4}} container direction="column">
          <VideoJS
            vjsTrigger={vjsTrigger}
            onCursorMove={() => {}}
            media={selectedMedia}
            setTranscriptAutoScroll={() => {}}
            startTime={startTime} />
        </Grid>
      </Grid>
    </Box>
  )
}
