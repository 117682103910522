import * as React from 'react';

import ThemeProvider from "@mui/system/ThemeProvider"
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline"
import { Outlet } from "react-router-dom";
import LocalStorage from '../../model/LocalStorage'
import './app.css'

import MiniDrawer from './Drawer.js'

export default function EditorApp({theme}) {
    const [localStorage, setLocalStorage] = React.useState(new LocalStorage())
    const [canDoInitialRender, setCanDoInitialRender] = React.useState(false)
    const [directory, setDirectory] = React.useState('')
    const [newBaseDirTrigger, setNewBaseDirTrigger] = React.useState(false)

    const tryOpenPersistedBaseDir = async () => {
        const gotPersistedBaseDir = await localStorage.tryOpenPersistedBaseDir()
        if (gotPersistedBaseDir) {
            setNewBaseDirTrigger(! newBaseDirTrigger)
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
        setCanDoInitialRender(true)
    }

    const onLoadFolderClicked = async (e) => {
        const gotStorageBaseDir = await localStorage.trySelectStorageBaseDir()   
        if (gotStorageBaseDir) {
            setNewBaseDirTrigger(! newBaseDirTrigger)
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
    }

    React.useEffect( () => {
        tryOpenPersistedBaseDir()
    }, [])
    
    const onFolderClosed = (e) => {
        localStorage.reset()
        setNewBaseDirTrigger(! newBaseDirTrigger)
        setDirectory(null)
    }

    if (!canDoInitialRender) {
        return(null)
    }

    return(
        <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Box width="100%" sx={{ display: 'flex' }}>
                <CssBaseline />
                {directory &&
                    <MiniDrawer
                        localStorage={localStorage}
                        newBaseDirTrigger={newBaseDirTrigger}
                    />
                }
                <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
                    <Outlet context={[localStorage, directory, newBaseDirTrigger, onLoadFolderClicked, onFolderClosed]}/>
                </Box>
            </Box>
        </ThemeProvider>
        </React.StrictMode>
    )
}