import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from '../Common/CircularProgressWithLabel'
import { CaretDown, PlusCircle } from "@phosphor-icons/react";
import { CreditCodes } from '../../model/CreditCodes'
import AddCreditModal from './AddCreditModal'
import AlertModal from '../Common/AlertModal'
import CreditPopover from './CreditPopover'
import { useOutletContext } from "react-router-dom";

export default function TranscriptionScreen() {

  const [localStorage] = useOutletContext()
  const jobManager = localStorage.jobManager

  const jobsList = React.useSyncExternalStore(jobManager.subscribe, jobManager.getSnapshot);

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [jobForMenu, setJobForMenu] = React.useState(null)

  const [remainingMinutes, setRemainingMinutes] = React.useState(0)
  const [remainingCreditList, setRemainingCreditList] = React.useState({})  // creditId -> minutes

  const updateRemainingCredit = async () => {
    const remainingCredits = await CreditCodes.getRemainingCredits()
    var remainingMinutes = 0
    setRemainingCreditList(remainingCredits)
    Object.values(remainingCredits).map(m => remainingMinutes += m)
    setRemainingMinutes(remainingMinutes)
  }
  React.useEffect( () => {
      updateRemainingCredit()
  }, [])

  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [creditPopoverAnchorEl, setCreditPopoverAnchorEl] = React.useState(null);

  const handleCreditPopoverClick = (e) => {
    setCreditPopoverAnchorEl(e.currentTarget);
  }
  const handleCreditPopoverClose = () => {
    setCreditPopoverAnchorEl(null)
  }

  const cancelUpload = () => {
    jobManager.cancelUpload(jobForMenu.jobId)
    handleClose()
  }

  const clearFromList = () => {
    jobManager.remove(jobForMenu.jobId)
    handleClose()
  }

  /**
   * Add Credit Modal
   */
  const [addCreditModalOpen, setAddCreditModalOpen] = React.useState(false)

  const handleAddCredit = () => {
    updateRemainingCredit()
    setAddCreditModalOpen(false)
  }

  /**
   * Confirm Credit Remove AlertModal
   */
  const [confirmCreditRemoveModalOpen, setConfirmCreditRemoveModalOpen] = React.useState(false)
  const [confirmCreditRemoveMessage, setConfirmCreditRemoveMessage] = React.useState('')
  const creditIdToRemove = React.useRef()

  const handleCreditRemove = (creditId) => {
    setConfirmCreditRemoveModalOpen(true);
    setConfirmCreditRemoveMessage(
      <Box>
        <Typography sx={{mb:1}}><b>{creditId}</b> ({remainingCreditList[creditId]} minutes remaining)</Typography>
        <Typography>Are you sure you want to forget this credit code on this device?</Typography>
      </Box>
    )
    creditIdToRemove.current = creditId
  }
  
  const removeCreditCode = () => {
    setConfirmCreditRemoveModalOpen(false)
    CreditCodes.removeCreditCode(creditIdToRemove.current)
    updateRemainingCredit()
  }


  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', userSelect: 'none'}}>
      <AddCreditModal
        isOpen={addCreditModalOpen}
        handleClose={ () => setAddCreditModalOpen(false)}
        handleAddCredit={handleAddCredit}
      />
      <AlertModal
        title={'Forget Credit Code'}
        message={confirmCreditRemoveMessage}
        isOpen={confirmCreditRemoveModalOpen}
        handleClose={ () => setConfirmCreditRemoveModalOpen(false)}
        positiveButtonLabel={'Forget'}
        handleConfirm={removeCreditCode}
      />
      <CreditPopover
        creditPopoverAnchorEl={creditPopoverAnchorEl}
        handleCreditPopoverClose={handleCreditPopoverClose}
        remainingCreditList={remainingCreditList}
        handleCreditRemove={handleCreditRemove}
      />
      <Grid container justifyContent="space-between" alignItems="center"
        sx={{
          minHeight: '64px',
          height: '64px',
          px: 4,
          backgroundColor: grey[50],
        }}>
          <Grid item>
            <h2 style={{margin: 0, lineHeight: 1, color: grey[700], display: 'flex', alignItems: 'center'}}>
              Transcription tasks
            </h2>
          </Grid>
          <Grid item>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button
                onClick={handleCreditPopoverClick}>
                {remainingMinutes} minutes credit
              </Button>
              <Button
                disableElevation
                sx={{ml: 2}}
                variant="contained"
                onClick={() => setAddCreditModalOpen(true)}
                startIcon={<PlusCircle weight="bold" />}
              >
                  Add credit
              </Button>
            </ButtonGroup>
          </Grid>
      </Grid>

      <Menu
        id={"job-menu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ elevation: 1 }}
      >
        { jobForMenu && jobForMenu.status === 'created' &&
          <MenuItem onClick={cancelUpload}>
            Cancel
          </MenuItem>
        }
        { jobForMenu && (jobForMenu.status === 'collected' || jobForMenu.status === 'failed' || jobForMenu.status === 'cancelled') &&
          <MenuItem onClick={clearFromList}>
            Clear from list
          </MenuItem>
        }
      </Menu>

      <Box sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {jobsList && Object.values(jobsList).map((job, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link variant='body1' href={"explorer/" + job.mediaFilename }>
                      {job.mediaFilename}
                    </Link><br />
                    <Typography variant="caption" sx={{ color: grey[400] }}>
                      {job.jobId}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{width: '1px', whiteSpace: 'nowrap'}}>
                    <Typography>{job.statusStringForUser()}</Typography>
                  </TableCell>
                  <TableCell sx={{width: '1px'}}>
                    {job.status === 'created'
                      ? <CircularProgressWithLabel progress={job.uploadProgressPercent} /> 
                      : job.status === 'failed' || job.status === 'cancelled'
                        ? null
                        : job.progressPercent
                          ? <CircularProgressWithLabel progress={job.progressPercent} /> 
                          : <CircularProgress value={job.progressPercent} />
                    }
                  </TableCell>
                  <TableCell sx={{width: '1px'}}>
                    <IconButton onClick={ (target) => {setJobForMenu(job); handleMenuClick(target)}}>
                      <CaretDown size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
