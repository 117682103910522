import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { Note } from "@phosphor-icons/react";
import './dingscreen.css'

export default function FileTable({mediaFiles}) {
    return(
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'scroll', overflowX: 'hidden'}}>
            <TableContainer sx={{p: 4}}>
                <Table>
                    <TableBody>
                        {mediaFiles.map((mf, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{p: 1}} style={{ width:'0.1%', whiteSpace: 'nowrap' }}>
                                    <Typography variant="button" sx={{ color: grey[400] }}>
                                        {mf.ext()}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    scope="row"
                                    sx={{p: 1}}>
                                    <Link variant='body1' component={RouterLink} to={"/app/explorer/" + mf.filename }>
                                        {mf.shortName()}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{p: 1}} style={{ width:'0.1%', whiteSpace: 'nowrap' }}>
                                    <Typography variant="button" sx={{ color: grey[400] }}>
                                        {(mf.annotations && mf.annotations.length > 0) &&
                                            <Button disabled size="small" startIcon={<Note size={16} />}>
                                                {mf.annotations.length}
                                            </Button>
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{p: 1}} style={{ width:'0.1%', whiteSpace: 'nowrap' }}>
                                    <Typography variant="button" sx={{ color: grey[400] }}>
                                        {mf.transcript && <Button disabled size="small">Transcript</Button>}
                                    </Typography>
                                </TableCell>
                                <TableCell sx= {{ p: 1 }} style={{ width:'0.1%', whiteSpace: 'nowrap' }}>
                                    <Button disabled size="small">
                                        {mf.path.join("/")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
        
}