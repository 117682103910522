import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VideoJS from '../VideoJSComponent/VideoJSComponent'
import './interview.css'
import AnnotationComponent from './AnnotationComponent'
import AnnotationEditor from './AnnotationEditorComponent'
import TranscriptView from './TranscriptViewComponent'
import Divider from '@mui/material/Divider';
import PeakGeneratorComponent from './PeakGeneratorComponent'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Timer } from "@phosphor-icons/react";
import Button from '@mui/material/Button';
import { Annotation } from '../../model/Annotation';
import { MediaFile } from '../../model/MediaFile';
import Tags from '../../model/Tags'
import OrderTranscriptionModal from '../OrderTranscription/OrderTranscriptionModal'
import { useParams } from 'react-router-dom';
import { Transcript } from '../../model/Transcript'
import { useOutletContext } from "react-router-dom";

export default function Interview() {

    const [localStorage, directory, newBaseDirTrigger, onLoadFolderClicked, onFolderClosed] = useOutletContext()

    // TODO: do away with selectedFile/selectedFilePath? Only used by FileExplorerComponent.
    // Replace with selectedMedia, which has file and path
    // const [selectedFile, setSelectedFile] = React.useState()
    const [selectedMedia, setSelectedMedia] = React.useState()

    const [mediaCursor, setMediaCursor] = React.useState(0)
    const [mediaCursorTimestamp, setMediaCursorTimestamp] = React.useState(0)
    const [startTime, setStartTime] = React.useState(0)
    const [vjsTrigger, setVjsTrigger] = React.useState(0)   // A hack to trigger an effect (play at timestamp) on the videojs componenet without
                                                            // any other state change
    const [transcriptAutoScroll, setTranscriptAutoScroll] = React.useState(false)

    const [transcript, setTranscript] = React.useState()

    const [annotations, setAnnotations] = React.useState([])
    const [selectedAnnotationIndex, setSelectedAnnotationIndex] = React.useState(-1)

    // TODO: tagSet holds the same information; can we just use that?
    const [tags, setTags] = React.useState([])
    const [tagSet, setTagSet] = React.useState(new Set())

    // Load file from route params
    const params = useParams();
    const loadFileFromParams = async () => {
        const filePathString = params["*"]
        const mediaFile = await MediaFile.fromFilePath(localStorage, filePathString)
        if (mediaFile === null) {
            setSelectedMedia(null)
            return
        }
        await mediaFile.loadFiles(localStorage)
        setSelectedMedia(mediaFile)
        setTranscript(mediaFile.transcript)
        setAnnotations(mediaFile.annotations)
        await loadTags()
    }
    React.useEffect( () => { loadFileFromParams() }, [params])

    const loadTags = async () => {
        const tagsIndex = await Tags.createTagIndex(localStorage)
        const tags = Object.keys(tagsIndex)
        setTags(tags)
        setTagSet(new Set(tags))
    }

    React.useEffect( () => {
        // setSelectedFile(null)
        setSelectedMedia(null)
    }, [newBaseDirTrigger])

    /**
     * OrderTranscriptionModal
     */
    const [orderTranscriptionModalOpen, setOrderTranscriptionModalOpen] = React.useState(false)
    const [mediaDuration, setMediaDuration] = React.useState()

    const onTranscribeClick = async () => {
        setOrderTranscriptionModalOpen(true)
    }

    const onTranscribeConfirm = () => {
        localStorage.jobManager.add(selectedMedia.filename)
        setOrderTranscriptionModalOpen(false)
    }

    const onPeaksWritten = () => {
        loadFileFromParams()
    }

    const onCursorMove = (time) => {
        setMediaCursor(time.toFixed(2))
        setMediaCursorTimestamp(time)
    }

    const onAddVttClick = (e) => {
        selectedMedia.tryAddVttFile(localStorage, () => loadFileFromParams())
    }

    const onTranscriptWordClick = (e) => {
        // Add 0.01sec to timecode so current line is highlighted correctly.
        let timecode = parseFloat(e.target.closest("[data-timecode]").getAttribute('data-timecode')) + 0.01
        setTranscriptAutoScroll(false)
        setStartTime(timecode)
        setVjsTrigger(vjsTrigger + 1)
    }

    const newAnnotation = () => {
        // TODO (bug): two new annotations in a row - save previous new annotation
        const newAnnotation = new Annotation({start: mediaCursor})
        newAnnotation.isNew = true
        setSelectedAnnotationIndex(-1)
        const newAnnotations = [...annotations, newAnnotation]
        setAnnotations(sortAnnotationsByTime(newAnnotations))
    }
    // Cannot consistently add a new annotation and set the selected index at the same time.
    // Instead, setSelectedAnnotationIndex = -1 when adding a new annotation
    // Then before next render, find the new annotation and setSelectedAnnotationIndex accordingly.
    if (selectedAnnotationIndex === -1) {
        annotations.map((a, index) => {
            if (a.isNew)
            setSelectedAnnotationIndex(index)
        })
    }

    const sortAnnotationsByTime = (annotations) => {
        annotations.sort( (a, b) => a.start - b.start )
        return annotations
    }

    const onCancelClicked = () => {
        if (annotations[selectedAnnotationIndex].isNew) {
            onDeleteClicked()
        } else {
            setSelectedAnnotationIndex(-1)
        }
    }

    const onDeleteClicked = () => {
        annotations.splice(selectedAnnotationIndex, 1)
        updateAnnotations(annotations)
        setSelectedAnnotationIndex(-1)
    }

    const onSaveClicked = (updatedAnnotation) => {
        const annotation = {
            start: updatedAnnotation.start,
            comment: updatedAnnotation.comment,
            tags: updatedAnnotation.tags,
        }
        updateTagSet(annotation.tags)
        annotations[selectedAnnotationIndex] = annotation
        updateAnnotations(sortAnnotationsByTime(annotations))
        setAnnotations(annotations)
        setSelectedAnnotationIndex(-1)
    }

    const updateAnnotations = (annotations) => {
        selectedMedia.writeAnnotationsToFile(localStorage, annotations)
    }

    const updateTranscript = (transcriptContent) => {
        setTranscript({
            content: transcriptContent
        })
        const tsString = Transcript.toVTTString(transcriptContent)
        selectedMedia.writeTranscriptToFile(localStorage, tsString)
    }

    const onTimecodeClick = (e) => {
        let timecode = e.target.closest("[data-timecode]").getAttribute('data-timecode')
        let index = Number(e.target.closest("[data-index]").getAttribute('data-index'))
        setTranscriptAutoScroll(true)
        loadAnnotation(index)
        playAtTimecode(timecode)
    }

    const loadAnnotation = (index) => {
        setSelectedAnnotationIndex(index)
    }

    const playAtTimecode = (timecode) => {
        setStartTime(timecode)
        setVjsTrigger(vjsTrigger + 1)
    }

    const updateTagSet = (newTags) => {
        newTags.forEach((tag) => tagSet.add(tag))
        setTagSet(tagSet)
        setTags(Array.from(tagSet))
    }

    if (!selectedMedia) {
        return (<div></div>)
    }

    if (selectedMedia && !selectedMedia.peaksBlobURL) {
        return(
            <div className="InterviewComponent" style={{ display: 'flex', height: '100%', maxWidth: '100%' }}>
                <PeakGeneratorComponent
                    localStorage={localStorage}
                    mediaFile={selectedMedia}
                    onPeaksWritten={onPeaksWritten}
                />
            </div>
        )
    }

    return (
        <div className="InterviewComponent" style={{ display: 'flex', height: '100%', maxWidth: '100%' }}>
            <OrderTranscriptionModal
                isOpen={orderTranscriptionModalOpen}
                handleConfirm={onTranscribeConfirm}
                selectedMedia={selectedMedia}
                mediaDuration={mediaDuration}
                handleClose={ () => setOrderTranscriptionModalOpen(false)}
            />
            <Grid item container sx={{ flexGrow: 1, my: 2 }}>
                <Grid item xs={6} container direction="column">
                    <Grid item sx={{
                        mx: 2,
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        borderColor: 'grey.200',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        backgroundColor: 'grey.50',
                        overflow: 'hidden'
                    }}>
                        <VideoJS
                            vjsTrigger={vjsTrigger}
                            onCursorMove={onCursorMove}
                            setTranscriptAutoScroll={setTranscriptAutoScroll}
                            media={selectedMedia}
                            reportMediaDuration={setMediaDuration}
                            startTime={startTime} />
                        <Divider></Divider>
                        <TranscriptView
                            transcript={transcript}
                            mediaCursorTimestamp={mediaCursorTimestamp}
                            autoScroll={transcriptAutoScroll}
                            onAddVttClick={onAddVttClick}
                            onTranscribeClick={onTranscribeClick}
                            onTranscriptWordClick={onTranscriptWordClick}
                            mediaFilename={selectedMedia.filename}
                            jobManager={localStorage.jobManager}
                            updateTranscript={updateTranscript}
                            onTranscriptReturned={ () => loadFileFromParams() }>
                        </TranscriptView>
                    </Grid>
                </Grid>
                <Grid item xs={6} container direction="column">
                    <Grid item sx={{ display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{
                            p: 1,
                            backgroundColor: 'grey.50',
                            border: 1,
                            borderColor: 'grey.200',
                            borderRadius: 1,
                            mr: 2,
                        }}>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Chip
                                        size="small"
                                        label={mediaCursor}
                                        sx={{mr: 0.5}}
                                        variant="outlined"
                                        icon={<Timer />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{float: 'right'}}>
                                        <Button
                                            disableElevation
                                            onClick={newAnnotation}
                                            variant="contained">
                                                New annotation
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item sx={{ px: 2, flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h6"><b>Annotations</b></Typography>
                        
                        <div style={{ overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <div style={{maxHeight: '1px'}}>
                                {annotations.map((a, index) => (
                                    (selectedAnnotationIndex === index)
                                        ? <AnnotationEditor
                                            selectedAnnotation={a}
                                            onCancelClicked={onCancelClicked}
                                            onDeleteClicked={onDeleteClicked}
                                            onSaveClicked={onSaveClicked}
                                            mediaCursor={mediaCursor}
                                            allTags={tags}>
                                            </AnnotationEditor>
                                        :
                                        <AnnotationComponent
                                            annotation={a}
                                            onTimecodeClick={onTimecodeClick}
                                            key={index}
                                            index={index} />
                                ))}                            
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}
