import * as React from 'react';

import ThemeProvider from "@mui/system/ThemeProvider"
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import { Waveform, FastForward, Hash, FileVideo, ReceiptX, Lightning, ShieldCheck, CloudSlash, BracketsCurly } from "@phosphor-icons/react";

export default function Homepage({theme}) {
    return(
        <React.StrictMode>
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <Container maxWidth="md" sx={{mb: 16, mt: 0, color: grey[800]}}>

                <Grid container spacing={2} sx={{mt:4, mb: 8}}>
                    <Grid>
                        <h1 style={{ margin: 0 }}>Ding!</h1>
                    </Grid>

                    <Grid display="flex" size="grow" alignItems='center' justifyContent='end'>
                        <Link variant="button" underline="hover" href="#" sx={{ mr: 4}}>
                            About
                        </Link>
                        <Link variant="button" underline="hover" href="#" sx={{ mr: 4}}>
                            Pricing
                        </Link>
                        <Link variant="button" underline="hover" href="#" sx={{ mr: 4}}>
                            Help
                        </Link>
                    </Grid>

                    <Grid style={{ alignContent: 'center' }}> 
                        <Button variant="contained" component={RouterLink} to="/app" disableElevation>
                            Open the App
                        </Button>
                    </Grid>
                </Grid>
                
                <Typography variant="h6" sx={{mb: 4}}>
                    Transcribe, comprehend & index your audio and video files.<br/>
                    No accounts, subscriptions or monthly limits. Data stored on your device.
                </Typography>

                <Typography variant="h5" sx={{mt: 12 }}><b>Smart tools to help humans manually review audio and video.</b></Typography>
                <Typography variant="h6">
                    <Grid container spacing={2} sx={{mt:2}}>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><Waveform size={32}/></Typography>
                            <Typography variant="button">Waveform & transcript</Typography><br />
                            Quickly navigate across your recordings
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><FastForward size={32}/></Typography>
                            <Typography variant="button">Time-coded notes</Typography><br />
                            Immediately jump back to crucial moments
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><Hash size={32}/></Typography>
                            <Typography variant="button">Tags & topic index</Typography><br />
                            Synthesise themes over all your material
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><FileVideo size={32}/></Typography>
                            <Typography variant="button">mp3, m4a, mov, mp4, wav...</Typography><br />
                            Supports recordings in common audio and video file formats
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><CloudSlash size={32}/></Typography>
                            <Typography variant="button">Local Storage</Typography><br />
                            Your data stays on your device. Back it up with git or Dropbox.
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><BracketsCurly size={32}/></Typography>
                            <Typography variant="button">It computes</Typography><br />
                            Simple JSON file format. Roll your own analyses with tools like jq.
                        </Grid>
                    </Grid>
                </Typography>

                <Typography variant="h5" sx={{mt: 12 }}><b>Transcription without subscription.</b></Typography>
                <Typography variant="h6">
                    <Grid container spacing={2} sx={{mt:2}}>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><ReceiptX size={32}/></Typography>
                            <Typography variant="button">No sign-up required</Typography><br />
                            Just pre-pay by the minute. We'll send you an access code to use on any device.
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><Lightning size={32}/></Typography>
                            <Typography variant="button">No monthly limits</Typography><br />
                            Transcribe as much as you like, whenever you like.
                        </Grid>
                        <Grid size={4} sx={{mb: 2}}>
                            <Typography><ShieldCheck size={32}/></Typography>
                            <Typography variant="button">No data harvesting</Typography><br />
                            We delete your media and transcription from our servers soon after returning it to you.
                        </Grid>
                    </Grid>
                </Typography>

                <Typography variant="h5" sx={{mt: 8, pb: 8 }}>For journalists, authors, researchers, oral historians and students.</Typography>

            </Container>    
        </ThemeProvider>
        </React.StrictMode>
    )    
}
