import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Routes, Route, HashRouter } from "react-router-dom";
import { createTheme } from '@mui/material/styles';
import './titillium.css';
import EditorApp from './components/EditorApp/EditorApp';
import DingScreen from "./components/DingScreen/DingScreen" 
import TagIndex from "./components/TagIndex/TagIndex" 
import Homepage from "./components/Homepage/Homepage" 
import Interview from "./components/Interview/Interview.js" 
import TranscriptionScreen from './components/TranscriptionScreen/TranscriptionScreen';
import reportWebVitals from './reportWebVitals';

const options = {
  viewonly: false
}

const theme = createTheme({
  typography: {
    fontFamily: [
      // 'ui-sans-serif', 'system-ui',
      'Asap',
      // 'Titillium Web',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const router = createBrowserRouter(
  [
    {
        path: "/",
        element: <Homepage theme={theme}/>,
    },
    {
      path: "/app",
      element: <EditorApp theme={theme}/>,
      children: [
        {
          path: "",
          element: <DingScreen />
        },
        {
          path: "tagindex",
          element: <TagIndex />
        },
        {
          path: "explorer/*",
          element: <Interview />
        },
        {
          path: "transcription",
          element: <TranscriptionScreen />
        }
      ]
    },
  ],
  {}
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
